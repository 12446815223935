import Link from "next/link";
import FacebookAltIcon from "../assets/facebook-alt-icon";
import InstagramAltIcon from "../assets/instagram-alt-icon";
import TwitterAltIcon from "../assets/twiiter-alt-icon";

interface DrawerProps {
  isOpen: boolean;
  onNavMenuClick: (id: string) => void;
}

export default function Drawer({ isOpen, onNavMenuClick }: DrawerProps) {
  // const handleClick = (e) => {
  //   console.log(`e:`, e.target.id);
  //   if (!e.target.id) onNavMenuClick();
  // };

  return (
    <div
      className={`${
        isOpen ? "block" : "hidden"
      } min-h-screen z-10 absolute right-0 left-0 bottom-0 top-16 flex justify-end items-start w-full bg-black/10`}
    >
      <div
        id="mobile-menu"
        className="bg-white min-w-[50%] px-4 min-h-screen shadow"
      >
        <ul>
          <li className="mb-4 mt-4">
            {/* <a onClick={() => onNavMenuClick("feature-trial")}>Feature Trial</a> */}
            <Link href="/carols-story"> Carol&apos;s Story</Link>
          </li>
          <li className="mb-4 mt-4">
            <a onClick={() => onNavMenuClick("updates")}>Updates</a>
          </li>
          <li className="mb-4">
            <a onClick={() => onNavMenuClick("programs")}>Programs</a>
          </li>
          <li className="mb-4">
            <a onClick={() => onNavMenuClick("press")}>Press</a>
          </li>
          <li className="mb-4">
            <a
              href="https://blog.glockfoundation.org/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Blog
            </a>
          </li>
          <li className="flex">
            <a
              href="https://facebook.com/glockfoundation"
              target="_blank"
              rel="noopener noreferrer"
              style={{ padding: "0.25rem" }}
            >
              <FacebookAltIcon className="w-5 h-5" />
            </a>
            <a
              href="https://twitter.com/glockfoundation"
              target="_blank"
              rel="noopener noreferrer"
              style={{ padding: "0.25rem" }}
            >
              <TwitterAltIcon className="w-5 h-5" />
            </a>
            <a
              href="https://instagram.com/glockfoundation"
              target="_blank"
              rel="noopener noreferrer"
              style={{ padding: "0.25rem", paddingRight: "1rem" }}
            >
              <InstagramAltIcon className="w-5 h-5" />
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}
