import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import { useState } from "react";
import Logo from "../public/glockfoundationlogo2.png";
import FacebookAltIcon from "./assets/facebook-alt-icon";
import InstagramAltIcon from "./assets/instagram-alt-icon";
import MenuIcon from "./assets/menu-icon";
import TwitterAltIcon from "./assets/twiiter-alt-icon";
import Drawer from "./common/drawer";

export default function Navbar() {
  const router = useRouter();
  const [menuOpen, setMenuOpen] = useState(false);

  const handleClick = (id: string) => {
    if (menuOpen) setMenuOpen(false);

    if (router.route === "/") {
      const element = document.getElementById(id);
      window.scrollTo({
        top: element ? element?.offsetTop - 50 : 0,
        behavior: "smooth",
      });
    } else {
      router.push(`/#${id}`);
    }
  };

  const handleMenuClick = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="navbar bg-base-100 fixed z-10">
      <div className="flex-1">
        <div className="relative w-40 md:w-56 h-8 md:h-10">
          <Link href="/">
            <a className="flex items-center">
              <Image
                src="/glockfoundationlogo2.png"
                layout="fill"
                objectFit="contain"
                className="mr-3 h-8"
                alt="Glock Foundation Logo"
              />
            </a>
          </Link>
        </div>
      </div>
      <div className="flex-none">
        <ul className="hidden lg:menu lg:menu-horizontal p-0">
          <li>
            {/* <a onClick={() => handleClick("feature-trial")}>Feature Trial</a> */}
            <Link href="/carols-story"> Carol&apos;s Story</Link>
          </li>
          <li>
            <a onClick={() => handleClick("updates")}>Updates</a>
          </li>
          <li>
            <a onClick={() => handleClick("programs")}>Programs</a>
          </li>
          <li>
            <a onClick={() => handleClick("press")}>Press</a>
          </li>
          <li>
            <a
              href="https://blog.glockfoundation.org/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Blog
            </a>
          </li>
          {/* <li>
            <a>About</a>
          </li> */}
          <li>
            <a
              href="https://facebook.com/glockfoundation"
              target="_blank"
              rel="noopener noreferrer"
              style={{ padding: "0.25rem" }}
            >
              <FacebookAltIcon className="w-5 h-5" />
            </a>
            <a
              href="https://twitter.com/glockfoundation"
              target="_blank"
              rel="noopener noreferrer"
              style={{ padding: "0.25rem" }}
            >
              <TwitterAltIcon className="w-5 h-5" />
            </a>
            <a
              href="https://instagram.com/glockfoundation"
              target="_blank"
              rel="noopener noreferrer"
              style={{ padding: "0.25rem", paddingRight: "1rem" }}
            >
              <InstagramAltIcon className="w-5 h-5" />
            </a>
          </li>
        </ul>
        <button className="lg:hidden" onClick={handleMenuClick}>
          <MenuIcon className="mr-4" />
        </button>
        <Link href="/donate">
          <a className="btn bg-success text-white rounded-full">Donate</a>
        </Link>
      </div>
      <Drawer isOpen={menuOpen} onNavMenuClick={handleClick} />
    </div>
  );
}
